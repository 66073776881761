.grid {
  
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left:auto;
    margin-right:auto;
}

.word {

    input {
        width:30px;
        height:30px;
        margin:3px;
        font-size: 14px;
        text-align: center;
    }

    display:block;

}

.orange {
    background-color: orange;
}

.green {
    background-color: green;
}

.white {
    background-color: white;
}

.hide {
    display:none;
}

.simple-keyboard.hg-layout-default .hg-button.hg-red {
    background: rgb(255, 0, 0, 0.7);
    color: white;
}

.simple-keyboard.hg-layout-default .hg-button.hg-green {
    background: rgb(0, 255, 0, 0.7);
    color: white;
}

.simple-keyboard.hg-layout-default .hg-button.hg-orange {
    background: orange;
    color: white;
}